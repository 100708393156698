import React, { useEffect, useState } from "react";
import { CgClose } from "react-icons/cg";
import { cities } from "../../utils/city";
import { days } from "../../utils/day";
import Switch from "react-switch";
import Pagination from "../Pagination";

const FilterMobile = ({
  toggle,
  filter,
  setFilter,
  appointments,
  setslotNum,
  slotNum,
  fetchData,
}) => {
  const [sToggle, setSToggle] = useState(filter.owner);
  const localcity = localStorage.getItem("city").toString();

  const handleChang = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setFilter({
      ...filter,
      [name]: value,
    });
  };

  // useEffect(() => {
  //   setFilter({
  //     ...filter,
  //     owner: sToggle,
  //   });
  // }, [sToggle]);

  // const toggleData = () => {
  //   const lab = JSON.parse(localStorage.getItem("fetchData"));
  //   if (lab) {
  //     setSToggle(lab.owner);
  //   }
  // };

  // useEffect(() => {
  //   toggleData();
  // }, []);

  const setSlot = (e) => {
    let value = e.target.value;

    setslotNum(value);
  };

  const handleChange = () => {
    setSToggle(!sToggle);
    setFilter({
      ...filter,
      owner: !sToggle,
    });
  };

  const resetDefault = () => {
    localStorage.removeItem("fetchData");
    setslotNum("");
    setSToggle(false);
    window.location.reload();
  };

  return (
    <>
      <div className="container filterMobileWrapper">
        <div className="row">
          <div className="col-12">
            <div className="filterIconContainer d-flex justify-content-between">
              <img src="Assets/filter.svg" alt="filter" />
              <CgClose onClick={() => toggle(false)} />
            </div>
            <div className="filterContainer">
              <div className="city-section">
                <div className="result d-flex justify-content-between align-items-center mt-4 mb-1">
                  {localcity === "null" ? (
                    <select
                      name="city"
                      onChange={handleChang}
                      className="form-select"
                    >
                      <option disabled>Select-city</option>
                      {cities.map((city) => {
                        return (
                          <>
                            <option
                              className="mb-0"
                              value={city}
                              selected={filter?.city === city}
                            >
                              {city}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  ) : (
                    <input
                      type="text"
                      value={localcity}
                      className="form-control"
                      disabled
                    />
                  )}
                </div>
              </div>
              <div className="date-section mt-3">
                <div className="date d-flex justify-content-between align-items-center mt-3 mb-1">
                  <input
                    type="date"
                    name="days"
                    onChange={handleChang}
                    value={filter?.days}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="result-section">
                <div className="result d-flex justify-content-between align-items-center mt-3 mb-1">
                  <select
                    name="pageLimit"
                    onChange={handleChang}
                    className="form-select"
                  >
                    <option selected>Select-limit</option>
                    {/* <option value="10" selected={filter?.pageLimit === "10"}>
                      10
                    </option> */}
                    <option value="25" selected={filter?.pageLimit === "25"}>
                      25
                    </option>
                    <option value="50" selected={filter?.pageLimit === "50"}>
                      50
                    </option>
                    <option value="75" selected={filter?.pageLimit === "75"}>
                      75
                    </option>
                    <option value="100" selected={filter?.pageLimit === "100"}>
                      100
                    </option>
                    <option value="150" selected={filter?.pageLimit === "150"}>
                      150
                    </option>
                    <option value="200" selected={filter?.pageLimit === "200"}>
                      200
                    </option>
                  </select>
                </div>
              </div>

              <div className="slot-section">
                <div className="date d-flex justify-content-between align-items-center mt-3 mb-1">
                  <select
                    name="slot"
                    onChange={setSlot}
                    className="form-select"
                  >
                    <option>Free Slot</option>
                    <option value="all" selected={slotNum && slotNum === "all"}>
                      All
                    </option>
                    <option value="1" selected={slotNum && slotNum === 1}>
                      Slot 1
                    </option>
                    <option value="2" selected={slotNum && slotNum === 2}>
                      Slot 2
                    </option>
                    <option value="3" selected={slotNum && slotNum === 3}>
                      Slot 3
                    </option>
                  </select>
                </div>
              </div>

              <div
                className="result d-flex justify-content-between align-items-center mt-3"
                onClick={resetDefault}
              >
                {Object.keys(appointments).length} Results
              </div>
              {localcity === "null" ? (
                <a
                  className="btn btn-large btn-primary text-white mt-3 w-100"
                  href={`${process.env.REACT_APP_URL}/locationview/?city=${filter.city}&stylist_id=0&date=${filter.days}`}
                  target="_blank"
                  style={{
                    fontSize: "12px",
                    borderRadius: "8px",
                    width: "80px",
                  }}
                >
                  City Map
                </a>
              ) : (
                <a
                  className="btn btn-large btn-primary text-white mt-3 w-100"
                  href={`${process.env.REACT_APP_URL}/locationview/?city=${localcity}&stylist_id=0&date=${filter.days}`}
                  target="_blank"
                  style={{
                    fontSize: "12px",
                    borderRadius: "8px",
                    width: "80px",
                  }}
                >
                  City Map
                </a>
              )}

              <div className="d-flex justify-content-end align-items-center mt-3">
                {
                  <>
                    All &nbsp;
                    <Switch
                      onChange={handleChange}
                      checked={sToggle}
                      onColor="#86d3ff"
                      onHandleColor="#2693e6"
                      handleDiameter={30}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={20}
                      width={48}
                      className="react-switch"
                    />
                    &nbsp;Only me
                  </>
                }
              </div>

              <div className="d-flex justify-content-center mt-4">
                <Pagination filter={filter} setFilter={setFilter} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterMobile;
