import React from "react";
import { useState } from "react";
import { useEffect } from "react";

const ProgressBar = ({
  servicetime,
  startTiming,
  percentage,
  slotDays,
  slotId,
  phone,
  sTime,
  eTime,
}) => {
  const [leftTimeto, setLeftTime] = useState("");
  const [totalTime, setTotalTime] = useState("");

  const totalTimeSlot = (comment, service) => {
    let serviceTime = 0;
    service.map((time) => {
      serviceTime += time.time * time.count;
    });

    const startTime = comment?.split("START at")[1]?.split(" ")[1];
    let startTimeMin =
      Number(startTime?.split(":")[0]) * 60 + Number(startTime?.split(":")[1]);

    const tot = parseInt(startTimeMin) + parseInt(serviceTime);
    let hrs = parseInt(tot / 60);
    const min = parseInt(tot % 60);

    const period = +hrs < 12 ? "AM" : "PM";

    let hours = +hrs % 12 || 12;
    hours = String(hours).padStart(2, "0");
    let minute = String(min).padStart(2, "0");
    const t = `${hours} : ${minute} ${period}`;

    setTotalTime(t);
  };

  // const timeLeft = (comment, service) => {
  //   let val = 0;
  //   service.map((time) => {
  //     val += time.time * time.count;
  //   });

  //   let hours = Math.floor(val / 60);
  //   let minutes = val % 60;

  //   let s1 = hours * 3600;
  //   let s2 = minutes * 60;
  //   /*Service time*/
  //   let totalServiceTimeSec = s1 + s2;

  //   const startTime = comment?.split("START at")[1]?.split(" ")[1];

  //   let startTimeSeconds =
  //     Number(startTime?.split(":")[0]) * 3600 +
  //     Number(startTime?.split(":")[1]) * 60;

  //     let startTimeMin =
  //     Number(startTime?.split(":")[0]) * 60 +
  //     Number(startTime?.split(":")[1])

  //     // console.log('comment',comment);
  //     // console.log('starttime',startTime,startTimeMin, val);
  //     const tot = parseInt(startTimeMin) + parseInt(val)
  //     const v = parseInt(tot / 60);
  //     const x = parseInt(tot % 60);
  //     // console.log(v, x)
  //     setTotalTime(`${v}hrs : ${x}min`)

  //   /*servicetime + start time*/
  //   let totalServiceSec = totalServiceTimeSec + startTimeSeconds;

  //   /*change second into hours, min, seconds*/
  //   let i = Number(totalServiceSec);
  //   let serviceHour = Math.floor(i / 3600);
  //   let serviceMin = Math.floor((i % 3600) / 60);

  //   const totalSeriveTimeInhoursNmin = `${serviceHour}: ${serviceMin}`;

  //   /*current time*/
  //   let date = new Date();

  //   let curHour = date.getHours();
  //   let curMin = date.getMinutes();

  //   const currentTimeInhoursNmin = `${curHour}:${curMin}`;

  //   // let fullTot = totalSec - t;

  //   /*we service time , ew real time*/
  //   const servTimeInMin = totalSeriveTimeInhoursNmin
  //     .split(":")
  //     .reduce((acc, time) => 60 * acc + +time);
  //   const currTimeInMin = currentTimeInhoursNmin
  //     .split(":")
  //     .reduce((acc, time) => 60 * acc + +time);

  //   const timeSlotLeft = servTimeInMin - currTimeInMin;
  //   const totaltime = servTimeInMin + currTimeInMin;

  //   // if (totaltime > 0) {
  //   //   const v = parseInt(totaltime / 60);
  //   //   const x = parseInt(totaltime % 60);
  //   //   setTotalTime(`${v}hrs : ${x}min`)
  //   //   return `${v} hrs ${x} min`;
  //   // } else {
  //   //   const v = parseInt(totaltime / 60);
  //   //   const x = parseInt(totaltime % 60);
  //   //   setTotalTime(`${v}hrs : ${x}min`)
  //   //   return `${Math.abs(v)} hrs ${Math.abs(x)} min`;
  //   // }

  //   if (timeSlotLeft > 0) {
  //     const v = parseInt(timeSlotLeft / 60);
  //     const x = parseInt(timeSlotLeft % 60);
  //     setLeftTime(`${v}hrs : ${x}min`)
  //     return `${v} hrs ${x} min`;
  //   } else {
  //     const v = parseInt(timeSlotLeft / 60);
  //     const x = parseInt(timeSlotLeft % 60);
  //     setLeftTime(`${v}hrs : ${x}min`)
  //     return `${Math.abs(v)} hrs ${Math.abs(x)} min`;
  //   }
  // };

  useEffect(() => {
    totalTimeSlot(sTime, eTime);
  }, [sTime, eTime]);

  let [percent, setPercent] = useState(percentage);

  const progressBarF = () => {
    if (startTiming) {
      let val = 0;
      servicetime.map((time) => {
        val += time.time;
      });

      let hours = Math.floor(val / 60);
      let minutes = val % 60;

      let s1 = hours * 3600;
      let s2 = minutes * 60;

      let service = s1 + s2;

      let i1 = Number(service);
      let h11 = parseInt(i1 / 3600);
      let m11 = Math.floor((i1 % 3600) / 60);

      let servicetimetotal = `${h11}: ${m11}`;
      const t3 = servicetimetotal
        .split(":")
        .reduce((acc, time) => 60 * acc + +time);

      const s = startTiming?.split("START at")[1]?.split(" ")[1];
      let seconds =
        Number(s?.split(":")[0]) * 3600 + Number(s?.split(":")[1]) * 60;

      const mainTimeService = seconds + service;

      let i = Number(mainTimeService);
      let h1 = parseInt(i / 3600);
      let m1 = Math.floor((i % 3600) / 60);

      let serviceTimeSlot = `${h1}: ${m1}`;
      let totservice = h1 * 60 + m1;

      let d = new Date();

      let hou = d.getHours();
      let min = d.getMinutes();

      let hSec = hours * 3600;
      let mSec = min * 60;

      let realtimeslot = `${hou}:${min}`;

      const t1 = serviceTimeSlot
        .split(":")
        .reduce((acc, time) => 60 * acc + +time);
      const t2 = realtimeslot
        .split(":")
        .reduce((acc, time) => 60 * acc + +time);

      const timeSlot = t1 - t2;

      if (timeSlot > 0) {
        const f1 = (t3 - timeSlot) / t3;
        const f2 = f1 * 100;
        setPercent(parseInt(f2));
      } else {
        setPercent("100%");
      }
    }
  };

  useEffect(() => {
    progressBarF();
  }, [ProgressBar]);

  return (
    <>
      {percent >= 0 ? (
        <a
          // href={`${process.env.REACT_APP_URL2}/admin/api/appointment/?date__exact=${slotDays}&id=${slotId}`}
          href={`${process.env.REACT_APP_URL2}/admin/api/appointment/?phone=${phone}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div
            className="progress mt-4"
            style={{
              width: "100%",
              height: "25px",
              position: "relative",
              borderRadius: "8px",
            }}
          >
            <div
              className="progress-bar "
              role="progressbar"
              style={{
                width: `${percent}%`,
                display: "flex",
                alignItems: "center",
                background: "#fddd5c",
              }}
            ></div>
            <span
              className="current-progress"
              style={{
                color: "black",
                position: "absolute",
                left: "30%",
                top: "3px",
              }}
            >
              {percent}% &nbsp;
              {totalTime ? <span>End at&nbsp;:&nbsp;{totalTime}</span> : null}
              {/* (End at&nbsp;:&nbsp;{totalTime && totalTime}) */}
            </span>
          </div>
        </a>
      ) : (
        <a
          // href={`${process.env.REACT_APP_URL2}/admin/api/appointment/?date__exact=${slotDays}&id=${slotId}`}
          href={`${process.env.REACT_APP_URL2}/admin/api/appointment/?phone=${phone}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div
            className="progress  mt-4"
            style={{
              width: "100%",
              height: "25px",
              position: "relative",
              borderRadius: "8px",
            }}
          >
            <div
              className="progress-bar "
              role="progressbar"
              style={{
                width: `100%`,
                display: "flex",
                alignItems: "center",
                background: "red",
              }}
            ></div>
            <span
              className="current-progress"
              style={{
                color: "black",
                position: "absolute",
                left: "40%",
                top: "3px",
              }}
            >
              Time Over
            </span>
          </div>
        </a>
      )}
      {/* <div
        className="progress"
        style={{ width: "100%", height: "25px", position: "relative" }}
      >
        <div
          className="progress-bar "
          role="progressbar"
          style={{
            width: `${percent}%`,
            display: "flex",
            alignItems: "center",
            background : "#90cbaa"
          }}
        ></div>
        <span
          className="current-progress"
          style={{
            color: "darkblue",
            position: "absolute",
            left: "50%",
            top: "3px",
          }}
        >
          {percent}%
        </span>
      </div> */}
    </>
  );
};

export default ProgressBar;
