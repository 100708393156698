import { useState, useEffect } from "react";
import "./login.css";
import { useNavigate } from "react-router-dom";


const Login = () => {
    let navigate = useNavigate();
    /*getting form data*/
    const [loginData, setLoginData] = useState({
        username: "",
        password: "",
    });


    useEffect(() => {
        
       const getLookSession = localStorage.getItem('token');
        if (getLookSession) {
          navigate("/dashboard", { replace: false });
          // if (location.state === null) {
          //   navigate("/timetable-login", { replace: false });
          // }
        }
      }, []);

    const [message, setMessage] = useState();

    /*updating the state value*/
    const eventHandle = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setLoginData({
            ...loginData,
            [name]: value,
        });
    };

   /*userLogin*/
    const loginUser = async (e) => {
        e.preventDefault();

        try {
            const url = `${process.env.REACT_APP_URL}/adminuser/v1/api`;

            const res = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    username: loginData.username,
                    password: loginData.password,
                }),
            })

            const data = await res.json();

                if(data.successCode == "4001") {

                    const tokan = data.token;
                    if(tokan){
                        localStorage.setItem('token',tokan);
                        sessionStorage.setItem("getLookSession", tokan);
                    }
                    const user = data.username;
                    const city = data?.city;

                    if(user) {
                        localStorage.setItem('user',user)
                    }

                    if(city) {
                        localStorage.setItem('city',city);
                    }

                    setLoginData({
                        username: "",
                        password: "",
                    })

                    navigate("/dashboard", 
                    // { state: { data: data } }
                    { replace: false }
                    );
                
                }else {
                    /* alert("wrong message");*/
                    setMessage("{*** Username or Password is invalid! ***}")
                    
                    setLoginData({
                        username: "",
                        password: "",
                    })
                }

        }catch (err) {
            setMessage("{*** Username or Password is invalid! ***}")
            console.log(err);
        }

    }

    useEffect(()=>{
        const timer = setTimeout(()=>{
            setMessage();
        },5000)
    },[message])

    return (
        <>
        <div className="bg-dark">
            <div className='text-center login-body'>
                <main className="form-signin">
                    <form>
                        <img src="/Assets/getlook_logo.svg" className="mb-4" alt="" width="200px" height="57" />
                        <h1 className="h3 mb-3 fw-normal">Please sign in</h1> 

                        <div className="form-floating">
                            <input type="email" className="form-control" name="username" placeholder="" value={loginData.username} onChange={eventHandle} />
                            <label >Username</label>
                        </div>
                        <div className="form-floating mt-3">
                            <input type="password" className="form-control" name="password" value={loginData.password} onChange={eventHandle} />
                            <label >Password</label>
                        </div>

                        {
                            message && <p className="text-danger">{message}</p>
                        }
                        <button className="w-100 btn btn-lg btn-primary" type="submit" onClick={loginUser}>Sign in</button>
                    </form>
                </main>
            </div>
        </div>
        </>
    )
}

export default Login