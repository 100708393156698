import Login from './Component/login';
import Dashboard from './Component/dashboard';
import {Routes,Route} from 'react-router-dom';

function App() {
  return (
   

    <Routes>
      <Route path='/' element={ <Login />} />
      <Route path='/dashboard' element={ <Dashboard />} />
    </Routes>
  );
}

export default App;
