import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { days } from "../utils/day";
import Filter from "./Filter New";
import Navbar from "./Navbar";
import Pagination from "./Pagination";
import { MdLocationOn } from "react-icons/md";
import { BsFillPersonCheckFill } from "react-icons/bs";
import Loader from "./Loader";
import moment from "moment";
import ProgressBar from "./progresstime";
import { Link } from "react-router-dom";
import "./dashboard.css";
import { BsWhatsapp } from "react-icons/bs";
import { BsPinMapFill } from "react-icons/bs";
import { FaIndianRupeeSign } from "react-icons/fa";
import { Accordion } from "react-bootstrap";

const Dashboard = () => {
  const [appointments, setAppointments] = useState([]);
  const [loader, setLoader] = useState(false);
  const [localDataa, setLocalData] = useState([]);
  const [serviceTime, setServicetime] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem("token");
  const adminUsername = localStorage.getItem("user");
  const [slotNum, setslotNum] = useState("all");

  const [filter, setFilter] = useState({
    city: "",
    days: "",
    page: "",
    pageLimit: "",
    owner: "",
  });

  useEffect(() => {
    const getLookSession = localStorage.getItem("token");
    if (getLookSession) {
      navigate("/dashboard", { replace: false });
    } else {
      navigate("/", { replace: false });
    }
  }, []);

  const firstfunction = () => {
    const local = JSON.parse(localStorage.getItem("fetchData"));
    const localcity = localStorage.getItem("city").toString();
    // console.log("city", localcity);

    if (local) {
      setFilter({
        city: local.city,
        days: local.days,
        page: local.page,
        pageLimit: local.pageLimit,
        owner: local.owner,
      });
    } else {
      setFilter({
        city: "Bangalore",
        days: days[0].value,
        page: 0,
        pageLimit: "100",
        owner: false,
      });
    }

    if (localcity === "null") {
      if (local) {
        setFilter({
          city: local.city,
          days: local.days,
          page: local.page,
          pageLimit: local.pageLimit,
          owner: local.owner,
        });
      } else {
        setFilter({
          city: "Bangalore",
          days: days[0].value,
          page: 0,
          pageLimit: "100",
          owner: false,
        });
      }
    } else {
      // alert(localcity);
      setFilter({
        city: localcity,
        days: days[0].value,
        page: 0,
        pageLimit: "100",
        owner: false,
      });
    }
  };
  useEffect(() => {
    firstfunction();
  }, []);

  const fetchData = async () => {
    setLoader(true);
    try {
      let unblk = new FormData();
      if (
        filter.city != "" ||
        filter.days != "" ||
        filter.page != "" ||
        filter.pageLimit != "" ||
        filter.owner != ""
      ) {
        unblk.append("date", filter.days);
        unblk.append("city", filter.city);
        unblk.append("page", filter.page);
        unblk.append("pageLimit", filter.pageLimit);
        unblk.append("owner", filter.owner);

        const res = await fetch(
          `${process.env.REACT_APP_URL}/allappointments/v1/api`,
          {
            method: "POST",
            headers: {
              Authorization: "Token " + token,
            },
            body: unblk,
          }
        );
        const data = await res.json();
        if (data) {
          localStorage.setItem("fetchData", JSON.stringify(filter));
          setAppointments(data);
          setLoader(false);
          console.log("api data", data);
        }
      }
    } catch (e) {
      console.log("error: " + e);
    }
  };

  useEffect(() => {
    fetchData();
  }, [filter]);

  /*Only use for sorting the slots*/
  useEffect(() => {
    let arr = [];
    let neww = [];

    Object?.keys(appointments)?.map((key, index) => {
      arr?.push(appointments[key]);
    });

    for (let i = 0; i < arr?.length; i++) {
      let e = arr[i]?.sort(compare);
      neww.push(e);
    }
    function compare(a, b) {
      if (a.slot < b.slot) {
        return -1;
      }
      if (a.slot > b.slot) {
        return 1;
      }
      return 0;
    }
    setLocalData(neww);
  }, [appointments]);

  const blockSlot = async (slotnumber, user, reason, date1) => {
    const slot1 = slotnumber;
    const username1 = user.split("-")[1].trim();
    const date = date1;
    const reason1 = "blocked";

    try {
      const res = await fetch(`${process.env.REACT_APP_URL}/blockslot/v1/api`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + token,
        },
        body: JSON.stringify({
          date: filter.days,
          slot: slot1,
          username: username1,
          reason: reason1,
          adminusername: adminUsername,
        }),
      });

      const data = await res.json();
      if (data) {
        // fetchData();
        window.open("/dashboard");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const blockSlotHalf = async (user, date1) => {
    const slot1 = "half";
    const username1 = user.split("-")[1].trim();
    const date = date1;
    const reason1 = "Half blocked";

    try {
      const res = await fetch(`${process.env.REACT_APP_URL}/blockslot/v1/api`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + token,
        },
        body: JSON.stringify({
          date: filter.days,
          slot: slot1,
          username: username1,
          reason: reason1,
          adminusername: adminUsername,
        }),
      });

      const data = await res.json();
      if (data) {
        // fetchData();
        window.open("/dashboard");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const blockAllSlot = async (user) => {
    const slot1 = "all";
    const username1 = user.split("-")[1].trim();
    /*const date = date1;*/
    const reason1 = "Off day";
    try {
      /*const url = `http://3.6.36.102/blockslot/v1/api`;*/
      const res = await fetch(`${process.env.REACT_APP_URL}/blockslot/v1/api`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + token,
        },
        body: JSON.stringify({
          date: filter.days,
          slot: slot1,
          username: username1,
          reason: reason1,
          adminusername: adminUsername,
        }),
      });

      const data = await res.json();
      if (data) {
        // fetchData();
        window.open("/dashboard");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const service = (e) => {
    let val = 0;
    e.map((time) => {
      val += time.time * time.count;
    });
    let hours = Math.floor(val / 60);
    let minutes = val % 60;
    return hours + " hours  " + minutes + " min";
  };

  const timeLeft = (comment, service) => {
    let val = 0;
    service.map((time) => {
      val += time.time * time.count;
    });

    let hours = Math.floor(val / 60);
    let minutes = val % 60;

    let s1 = hours * 3600;
    let s2 = minutes * 60;
    /*Service time*/
    let totalServiceTimeSec = s1 + s2;

    const startTime = comment?.split("START at")[1]?.split(" ")[1];

    let startTimeSeconds =
      Number(startTime?.split(":")[0]) * 3600 +
      Number(startTime?.split(":")[1]) * 60;

    /*servicetime + start time*/
    let totalServiceSec = totalServiceTimeSec + startTimeSeconds;

    /*change second into hours, min, seconds*/
    let i = Number(totalServiceSec);
    let serviceHour = Math.floor(i / 3600);
    let serviceMin = Math.floor((i % 3600) / 60);

    const totalSeriveTimeInhoursNmin = `${serviceHour}: ${serviceMin}`;

    /*current time*/
    let date = new Date();

    let curHour = date.getHours();
    let curMin = date.getMinutes();

    const currentTimeInhoursNmin = `${curHour}:${curMin}`;

    // let fullTot = totalSec - t;

    /*we service time , ew real time*/
    const servTimeInMin = totalSeriveTimeInhoursNmin
      .split(":")
      .reduce((acc, time) => 60 * acc + +time);
    const currTimeInMin = currentTimeInhoursNmin
      .split(":")
      .reduce((acc, time) => 60 * acc + +time);

    const timeSlotLeft = servTimeInMin - currTimeInMin;

    if (timeSlotLeft > 0) {
      const v = parseInt(timeSlotLeft / 60);
      const x = parseInt(timeSlotLeft % 60);
      return `${v} hrs ${x} min`;
    } else {
      const v = parseInt(timeSlotLeft / 60);
      const x = parseInt(timeSlotLeft % 60);
      return `${Math.abs(v)} hrs ${Math.abs(x)} min`;
    }
  };

  const unblockSlot = async (slotnumber, user, reason, date1) => {
    const slot1 = slotnumber;
    const username1 = user.split("-")[1].trim();
    const date = date1;
    const reason1 = "unblocked";
    const adminusername = adminUsername;
    if (token) {
      try {
        let unblk = new FormData();
        unblk.append("date", filter.days);
        unblk.append("delete", true);
        unblk.append("slot", slot1);
        unblk.append("username", username1);
        unblk.append("reason", reason1);
        unblk.append("adminusername", adminusername);

        const res = await fetch(
          `${process.env.REACT_APP_URL}/blockslot/v1/api`,
          {
            method: "POST",
            headers: {
              Authorization: "Token " + token,
            },
            body: unblk,
          }
        );

        const data = await res.json();
        if (data) {
          // fetchData();
          window.open("/dashboard");
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const totalCost = (e) => {
    let val = 0;
    e.map((cost) => {
      val += cost.cost * cost.count;
    });
    return val;
  };

  /*Free Slot Filtering*/
  const slotFilter = async () => {
    if (slotNum !== "all") {
      try {
        setLoader(true);
        let finalFilteredArray = {};
        if (slotNum !== "") {
          let unblk = new FormData();
          unblk.append("date", filter.days);
          unblk.append("city", filter.city);
          unblk.append("page", filter.page);
          unblk.append("pageLimit", filter.pageLimit);
          unblk.append("owner", filter.owner);

          const res = await fetch(
            `${process.env.REACT_APP_URL}/allappointments/v1/api`,
            {
              method: "POST",
              headers: {
                Authorization: "Token " + token,
              },
              body: unblk,
            }
          );
          const data = await res.json();
          for (const key in data) {
            data[key].map((ele) => {
              if (ele.slot == slotNum && ele.slotStatus === "FREE") {
                finalFilteredArray[key] = data[key];
                return;
              }
            });
          }
          console.log("finalFilteredArray", finalFilteredArray);
          setAppointments(finalFilteredArray);
        }
        setLoader(false);
      } catch (e) {
        console.log("error: " + e);
      }
    } else {
      fetchData();
    }
  };
  useEffect(() => {
    slotFilter();
    // return ()=>{

    // }
  }, [slotNum]);

  const stylist_id = async (id) => {
    // alert(id);
    setLoader(true);
    try {
      let unblk = new FormData();
      unblk.append("date", filter.days);
      unblk.append("city", filter.city);
      unblk.append("stylist_id", id);

      const res = await fetch(
        `${process.env.REACT_APP_URL}/allappointments/v1/api`,
        {
          method: "POST",
          headers: {
            // "content-type": "multipart/form-data",
            Authorization: "Token " + token,
          },
          body: unblk,
        }
      );
      const data = await res.json();
      if (data) {
        setAppointments(data);
        setLoader(false);
        setslotNum("");
      }
      console.log("api data", data);
    } catch (e) {
      console.log("error: " + e);
    }
  };

  const commentMenu = (data) => {
    // console.log(data)
    const ssting = data.split("--");
    console.log(ssting);
    const tComent = ssting.slice(1);
    return tComent.toString();
  };

  return (
    <>
      <div className="header">
        <Navbar
          filter={filter}
          setFilter={setFilter}
          appointments={appointments}
          setAppointments={setAppointments}
          setslotNum={setslotNum}
          slotNum={slotNum}
          fetchData={fetchData}
        />
        <Filter
          filter={filter}
          setFilter={setFilter}
          appointments={appointments}
          setAppointments={setAppointments}
          setslotNum={setslotNum}
          slotNum={slotNum}
          fetchData={fetchData}
          setLoader={setLoader}
          // localcity={localcity}
        />
        {/* table start */}
        <div className="table-container-new mb-3">
          <div className="container-fluid px-3">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">
                    <div className="">
                      <input type="checkbox" />
                    </div>
                  </th>
                  <th scope="col-2">Stylist</th>
                  <th scope="col">Slot 1</th>
                  <th scope="col">Slot 2</th>
                  <th scope="col">Slot 3</th>
                </tr>
              </thead>
              <tbody>
                {loader ? (
                  <Loader />
                ) : Object.keys(appointments).length ? (
                  <>
                    {Object.keys(appointments).map(function (key, index) {
                      return (
                        <>
                          <tr>
                            <th>
                              <div className="" style={{ fontSize: "14px" }}>
                                {/* <input type="checkbox" /> */}
                                {index + 1}.
                              </div>
                            </th>
                            <td>
                              <p
                                className="mb-2"
                                onClick={() => stylist_id(key.split("-")[3])}
                                style={{
                                  cursor: "pointer",
                                  fontWeight: "700",
                                }}
                              >
                                {key.split("-")[0]}
                                &nbsp;
                                {/* {
                                key.split('-')[1]
                              } */}
                              </p>

                              <a
                                href={`https://api.whatsapp.com/send?phone=${`91${
                                  key.split("-")[1]
                                }`}&text=Hello, you have an appointment in the morning at 9:30 AM. Please call the client one hour before and book the cab on time. If you face any issue in booking cab, please contact us immediately. Penalty of Rs. 50 will be applied if not followed. Thanks`}
                                target="_blank"
                                className="text-dark"
                              >
                                <BsWhatsapp />
                                &nbsp;&nbsp;
                                {key.split("-")[1]}
                              </a>
                              <p className="pt-2 mb-2">
                                <MdLocationOn />
                                &nbsp;&nbsp;
                                {key.split("-")[2]}
                              </p>
                              <a
                                className="text-dark"
                                href={`${
                                  process.env.REACT_APP_URL
                                }/locationview?city=${
                                  filter.city
                                }&stylist_id=${key
                                  .slice(key.lastIndexOf(" "))
                                  .trim()}&date=${filter.days}`}
                                target="_blank"
                              >
                                <BsPinMapFill />
                                &nbsp;&nbsp; Maps
                              </a>
                              <br />
                              <br />
                              <button
                                className="blockBtn"
                                aria-disabled="true"
                                onClick={() => blockAllSlot(key)}
                              >
                                Block All
                              </button>
                              <br />
                              <br />
                              <button
                                className="halfDayBtn"
                                onClick={() => {
                                  blockSlotHalf(key, filter.days);
                                }}
                              >
                                Half Day
                              </button>
                            </td>

                            <td className="table-margin">
                              {appointments[key].map((slot, index) => {
                                console.log("phone", slot);
                                return (
                                  <>
                                    {slot.slot === 1 && (
                                      <>
                                        {slot.slotStatus === "FREE" ? (
                                          <>
                                            <button
                                              className="blockBtn"
                                              onClick={() => {
                                                blockSlot(
                                                  slot.slot,
                                                  key,
                                                  filter.days
                                                );
                                              }}
                                            >
                                              BLOCK
                                            </button>
                                            <br />
                                            <br />
                                            <p className="ps-4">FREE</p>
                                          </>
                                        ) : slot.slotStatus === "BLOCKED" ? (
                                          <>
                                            <div
                                              style={{
                                                width: "100%",
                                                height: "100%",
                                              }}
                                            >
                                              <button
                                                className="ReleaseBtn"
                                                onClick={() =>
                                                  unblockSlot(
                                                    slot.slot,
                                                    key,
                                                    filter.days
                                                  )
                                                }
                                              >
                                                Release
                                              </button>
                                              <br />
                                              <br />
                                              <p className="ps-4">BLOCKED</p>
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            <ul
                                              style={{ marginBottom: "30px" }}
                                            >
                                              <button
                                                className="ReleaseBtn mb-3"
                                                onClick={() =>
                                                  unblockSlot(
                                                    slot.slot,
                                                    key,
                                                    filter.days
                                                  )
                                                }
                                              >
                                                Release
                                              </button>
                                              <span className="ms-3">
                                                {slot.stylist_seen ? (
                                                  <BsFillPersonCheckFill />
                                                ) : null}
                                              </span>
                                              <span className="ms-3">
                                                <a
                                                  href={`https://api.whatsapp.com/send?phone=91${slot.phone.trim()}&text=Hi ${
                                                    slot.name
                                                  }, This is Misha from GetLook - Salon at Home. 
                                                  
                                                  %0aIt would great if you can send your Whats App location as we are unable to get your exact Address. This will help our stylist to book Cab for your Location.
                                                  `}
                                                  target="_blank"
                                                  className="text-dark"
                                                >
                                                  <BsWhatsapp />
                                                </a>
                                              </span>
                                              {slot?.customer_discount < 10 && (
                                                <span className="ms-3">
                                                  <img
                                                    src="/Assets/rupees.svg"
                                                    alt="rupees"
                                                    width={15}
                                                  />
                                                </span>
                                              )}

                                              <li>Area : {slot.area.name}</li>
                                              <li>Timing: {slot.timing} </li>
                                              <li>
                                                Total Price: Rs.{" "}
                                                {totalCost(
                                                  slot.appointmentitem_set
                                                )}
                                              </li>

                                              <li>
                                                Service Time : &nbsp;
                                                {service(
                                                  slot.appointmentitem_set
                                                )}
                                              </li>

                                              <Accordion>
                                                <Accordion.Item eventKey="0">
                                                  <Accordion.Header>
                                                    Live Status
                                                  </Accordion.Header>
                                                  <Accordion.Body>
                                                    <ul className="liveStatus">
                                                      <li>
                                                        In Transit:&nbsp;
                                                        {slot.stylist_comment?.includes(
                                                          "TRANSIT at"
                                                        )
                                                          ? slot.stylist_comment
                                                              ?.split(
                                                                "TRANSIT at"
                                                              )[1]
                                                              ?.split(" ")[1]
                                                          : null}
                                                      </li>
                                                      <li>
                                                        Start:&nbsp;
                                                        {slot.stylist_comment?.includes(
                                                          "START at"
                                                        )
                                                          ? slot.stylist_comment
                                                              ?.split(
                                                                "START at"
                                                              )[1]
                                                              ?.split(" ")[1]
                                                          : null}
                                                      </li>
                                                      <li>
                                                        End:&nbsp;
                                                        {slot.stylist_comment?.includes(
                                                          "END at"
                                                        )
                                                          ? slot.stylist_comment
                                                              ?.split(
                                                                "END at"
                                                              )[1]
                                                              ?.split(" ")[1]
                                                          : null}
                                                      </li>
                                                      <li>
                                                        Time Left : &nbsp;
                                                        {slot.stylist_comment?.includes(
                                                          "END at"
                                                        ) ? null : slot.stylist_comment?.includes(
                                                            "START at"
                                                          ) ? (
                                                          <>
                                                            {timeLeft(
                                                              slot.stylist_comment,
                                                              slot.appointmentitem_set
                                                            )}
                                                          </>
                                                        ) : null}
                                                      </li>
                                                    </ul>
                                                  </Accordion.Body>
                                                </Accordion.Item>
                                              </Accordion>

                                              <li>
                                                Comment :{" "}
                                                {commentMenu(
                                                  slot?.stylist_comment
                                                )}
                                                {/* {slot.stylist_comment?.includes(
                                                  "Started Late"
                                                ) ? (
                                                  <>
                                                    Started Late
                                                    {slot.stylist_comment?.includes(
                                                      "Extra Time"
                                                    )
                                                      ? "| Extra Time"
                                                      : slot.stylist_comment?.includes(
                                                          "More Time"
                                                        )
                                                      ? "| More Time"
                                                      : slot.stylist_comment?.includes(
                                                          "Finished Fast"
                                                        )
                                                      ? "| Finished Fast"
                                                      : null}
                                                  </>
                                                ) : null} */}
                                              </li>

                                              <li>
                                                <div className="d-flex align-items-center">
                                                  <MdLocationOn />

                                                  <span className="ms-2">
                                                    {slot.feedback_form_received ? (
                                                      " Address Verified"
                                                    ) : (
                                                      <a
                                                        style={{
                                                          color: "#a61009",
                                                        }}
                                                        href={`${process.env.REACT_APP_URL}/mapLocationAdmin/?id=${slot.id}`}
                                                        target="_blank"
                                                      >
                                                        Address Not Verified
                                                      </a>
                                                    )}
                                                  </span>
                                                </div>
                                              </li>

                                              {slot.stylist_comment?.includes(
                                                "END at"
                                              ) ? null : slot.stylist_comment?.includes(
                                                  "START at"
                                                ) ? (
                                                <ProgressBar
                                                  servicetime={
                                                    slot.appointmentitem_set
                                                  }
                                                  startTiming={
                                                    slot.stylist_comment
                                                  }
                                                  percentage={0}
                                                  slotDays={filter.days}
                                                  slotId={slot.id}
                                                  phone={slot.phone}
                                                  sTime={slot?.stylist_comment}
                                                  eTime={
                                                    slot?.appointmentitem_set
                                                  }
                                                />
                                              ) : null}

                                              {slot.status === 0 ? (
                                                <a
                                                  // href={`${process.env.REACT_APP_URL2}/admin/api/appointment/?date__exact=${filter.days}&id=${slot.id}`}
                                                  // target="_blank"
                                                  // rel="noopener noreferrer"
                                                  // className="btn  pending shadow-none mt-3"
                                                  href={`${process.env.REACT_APP_URL2}/admin/api/appointment/?phone=${slot.phone}`}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="btn pending shadow-none mt-3"
                                                >
                                                  Pending
                                                </a>
                                              ) : slot.status === 1 ? (
                                                <a
                                                  // href={`${process.env.REACT_APP_URL2}/admin/api/appointment/?date__exact=${filter.days}&id=${slot.id}`}
                                                  // target="_blank"
                                                  // rel="noopener noreferrer"
                                                  // className="btn  confirmed shadow-none mt-3"
                                                  href={`${process.env.REACT_APP_URL2}/admin/api/appointment/?phone=${slot.phone}`}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="btn  confirmed shadow-none mt-3"
                                                >
                                                  Confirmed
                                                </a>
                                              ) : slot.status === 13 ? (
                                                <a
                                                  // href={`${process.env.REACT_APP_URL2}/admin/api/appointment/?date__exact=${filter.days}&id=${slot.id}`}
                                                  // target="_blank"
                                                  // rel="noopener noreferrer"
                                                  // className="btn inTransit shadow-none mt-3"
                                                  href={`${process.env.REACT_APP_URL2}/admin/api/appointment/?phone=${slot.phone}`}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="btn  inTransit shadow-none mt-3"
                                                >
                                                  In Transit
                                                </a>
                                              ) : slot.status === 3 ? (
                                                <a
                                                  // href={`${process.env.REACT_APP_URL2}/admin/api/appointment/?date__exact=${filter.days}&id=${slot.id}`}
                                                  // target="_blank"
                                                  // rel="noopener noreferrer"
                                                  // className="btn stylist_confirm shadow-none mt-3"
                                                  href={`${process.env.REACT_APP_URL2}/admin/api/appointment/?phone=${slot.phone}`}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="btn  stylist_confirm shadow-none mt-3"
                                                >
                                                  Stylist Confirmed
                                                </a>
                                              ) : slot.status === 5 ? (
                                                <a
                                                  // href={`${process.env.REACT_APP_URL2}/admin/api/appointment/?date__exact=${filter.days}&id=${slot.id}`}
                                                  // target="_blank"
                                                  // rel="noopener noreferrer"
                                                  // className="btn end shadow-none mt-3"
                                                  href={`${process.env.REACT_APP_URL2}/admin/api/appointment/?phone=${slot.phone}`}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="btn end shadow-none mt-3"
                                                >
                                                  End
                                                </a>
                                              ) : null}
                                            </ul>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                );
                              })}
                            </td>

                            <td className="table-margin">
                              {appointments[key].map((slot, index) => {
                                return (
                                  <>
                                    {slot.slot === 2 && (
                                      <>
                                        {slot.slotStatus === "FREE" ? (
                                          <>
                                            <button
                                              className="blockBtn"
                                              onClick={() => {
                                                blockSlot(
                                                  slot.slot,
                                                  key,
                                                  filter.days
                                                );
                                              }}
                                            >
                                              BLOCK
                                            </button>
                                            <br />
                                            <br />
                                            <p className="ps-4">FREE</p>
                                          </>
                                        ) : slot.slotStatus === "BLOCKED" ? (
                                          <>
                                            <div
                                              style={{
                                                width: "100%",
                                                height: "100%",
                                              }}
                                            >
                                              <button
                                                className="ReleaseBtn"
                                                onClick={() =>
                                                  unblockSlot(
                                                    slot.slot,
                                                    key,
                                                    filter.days
                                                  )
                                                }
                                              >
                                                Release
                                              </button>
                                              <br />
                                              <br />
                                              <p className="ps-4">BLOCKED</p>
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            <ul
                                              style={{ marginBottom: "30px" }}
                                            >
                                              <button
                                                className="ReleaseBtn mb-3"
                                                onClick={() =>
                                                  unblockSlot(
                                                    slot.slot,
                                                    key,
                                                    filter.days
                                                  )
                                                }
                                              >
                                                Release
                                              </button>
                                              <span className="ms-3">
                                                {slot.stylist_seen ? (
                                                  <BsFillPersonCheckFill />
                                                ) : null}
                                              </span>
                                              <span className="ms-3">
                                                <a
                                                  href={`https://api.whatsapp.com/send?phone=91${slot.phone.trim()}&text=Hi ${
                                                    slot.name
                                                  },This is Misha from GetLook - Salon at Home.
                                                  %0aIt would great if you can send your Whats App location as we are unable to get your exact Address. This will help our stylist to book Cab for your Location.
                                                  `}
                                                  target="_blank"
                                                  className="text-dark"
                                                >
                                                  <BsWhatsapp />
                                                </a>
                                              </span>
                                              {slot?.customer_discount < 10 && (
                                                <span className="ms-3">
                                                  <img
                                                    src="/Assets/rupees.svg"
                                                    alt="rupees"
                                                    width={15}
                                                  />
                                                </span>
                                              )}

                                              <li>Area : {slot.area.name}</li>
                                              <li>Timing: {slot.timing} </li>
                                              <li>
                                                Total Price: Rs.{" "}
                                                {totalCost(
                                                  slot.appointmentitem_set
                                                )}
                                              </li>

                                              <li>
                                                Service Time : &nbsp;
                                                {service(
                                                  slot.appointmentitem_set
                                                )}
                                              </li>

                                              <Accordion>
                                                <Accordion.Item eventKey="0">
                                                  <Accordion.Header>
                                                    Live Status
                                                  </Accordion.Header>
                                                  <Accordion.Body>
                                                    <ul className="liveStatus">
                                                      <li>
                                                        In Transit:&nbsp;
                                                        {slot.stylist_comment?.includes(
                                                          "TRANSIT at"
                                                        )
                                                          ? slot.stylist_comment
                                                              ?.split(
                                                                "TRANSIT at"
                                                              )[1]
                                                              ?.split(" ")[1]
                                                          : null}
                                                      </li>
                                                      <li>
                                                        Start:&nbsp;
                                                        {slot.stylist_comment?.includes(
                                                          "START at"
                                                        )
                                                          ? slot.stylist_comment
                                                              ?.split(
                                                                "START at"
                                                              )[1]
                                                              ?.split(" ")[1]
                                                          : null}
                                                      </li>
                                                      <li>
                                                        End:&nbsp;
                                                        {slot.stylist_comment?.includes(
                                                          "END at"
                                                        )
                                                          ? slot.stylist_comment
                                                              ?.split(
                                                                "END at"
                                                              )[1]
                                                              ?.split(" ")[1]
                                                          : null}
                                                      </li>
                                                      <li>
                                                        Time Left : &nbsp;
                                                        {slot.stylist_comment?.includes(
                                                          "END at"
                                                        ) ? null : slot.stylist_comment?.includes(
                                                            "START at"
                                                          ) ? (
                                                          <>
                                                            {timeLeft(
                                                              slot.stylist_comment,
                                                              slot.appointmentitem_set
                                                            )}
                                                          </>
                                                        ) : null}
                                                      </li>
                                                    </ul>
                                                  </Accordion.Body>
                                                </Accordion.Item>
                                              </Accordion>

                                              <li>
                                                Comment :{" "}
                                                {commentMenu(
                                                  slot?.stylist_comment
                                                )}
                                                {/* {slot.stylist_comment?.includes(
                                                  "Started Late"
                                                ) ? (
                                                  <>
                                                    Started Late
                                                    {slot.stylist_comment?.includes(
                                                      "Extra Time"
                                                    )
                                                      ? "| Extra Time"
                                                      : slot.stylist_comment?.includes(
                                                          "More Time"
                                                        )
                                                      ? "| More Time"
                                                      : slot.stylist_comment?.includes(
                                                          "Finished Fast"
                                                        )
                                                      ? "| Finished Fast"
                                                      : null}
                                                  </>
                                                ) : null} */}
                                              </li>

                                              <li>
                                                <div className="d-flex align-items-center">
                                                  <MdLocationOn />

                                                  <span className="ms-2">
                                                    {slot.feedback_form_received ? (
                                                      " Address Verified"
                                                    ) : (
                                                      <a
                                                        style={{
                                                          color: "#a61009",
                                                        }}
                                                        href={`${process.env.REACT_APP_URL}/mapLocationAdmin/?id=${slot.id}`}
                                                        target="_blank"
                                                      >
                                                        Address Not Verified
                                                      </a>
                                                    )}
                                                  </span>
                                                </div>
                                              </li>

                                              {slot.stylist_comment?.includes(
                                                "END at"
                                              ) ? null : slot.stylist_comment?.includes(
                                                  "START at"
                                                ) ? (
                                                <ProgressBar
                                                  servicetime={
                                                    slot.appointmentitem_set
                                                  }
                                                  startTiming={
                                                    slot.stylist_comment
                                                  }
                                                  percentage={0}
                                                  slotDays={filter.days}
                                                  slotId={slot.id}
                                                  phone={slot.phone}
                                                  sTime={slot?.stylist_comment}
                                                  eTime={
                                                    slot?.appointmentitem_set
                                                  }
                                                />
                                              ) : null}

                                              {slot.status === 0 ? (
                                                <a
                                                  // href={`${process.env.REACT_APP_URL2}/admin/api/appointment/?date__exact=${filter.days}&id=${slot.id}`}
                                                  // target="_blank"
                                                  // rel="noopener noreferrer"
                                                  // className="btn  pending shadow-none mt-3"
                                                  href={`${process.env.REACT_APP_URL2}/admin/api/appointment/?phone=${slot.phone}`}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="btn pending shadow-none mt-3"
                                                >
                                                  Pending
                                                </a>
                                              ) : slot.status === 1 ? (
                                                <a
                                                  // href={`${process.env.REACT_APP_URL2}/admin/api/appointment/?date__exact=${filter.days}&id=${slot.id}`}
                                                  // target="_blank"
                                                  // rel="noopener noreferrer"
                                                  // className="btn  confirmed shadow-none mt-3"
                                                  href={`${process.env.REACT_APP_URL2}/admin/api/appointment/?phone=${slot.phone}`}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="btn  confirmed shadow-none mt-3"
                                                >
                                                  Confirmed
                                                </a>
                                              ) : slot.status === 13 ? (
                                                <a
                                                  // href={`${process.env.REACT_APP_URL2}/admin/api/appointment/?date__exact=${filter.days}&id=${slot.id}`}
                                                  // target="_blank"
                                                  // rel="noopener noreferrer"
                                                  // className="btn inTransit shadow-none mt-3"
                                                  href={`${process.env.REACT_APP_URL2}/admin/api/appointment/?phone=${slot.phone}`}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="btn  inTransit shadow-none mt-3"
                                                >
                                                  In Transit
                                                </a>
                                              ) : slot.status === 3 ? (
                                                <a
                                                  // href={`${process.env.REACT_APP_URL2}/admin/api/appointment/?date__exact=${filter.days}&id=${slot.id}`}
                                                  // target="_blank"
                                                  // rel="noopener noreferrer"
                                                  // className="btn stylist_confirm shadow-none mt-3"
                                                  href={`${process.env.REACT_APP_URL2}/admin/api/appointment/?phone=${slot.phone}`}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="btn  stylist_confirm shadow-none mt-3"
                                                >
                                                  Stylist Confirmed
                                                </a>
                                              ) : slot.status === 5 ? (
                                                <a
                                                  // href={`${process.env.REACT_APP_URL2}/admin/api/appointment/?date__exact=${filter.days}&id=${slot.id}`}
                                                  // target="_blank"
                                                  // rel="noopener noreferrer"
                                                  // className="btn end shadow-none mt-3"
                                                  href={`${process.env.REACT_APP_URL2}/admin/api/appointment/?phone=${slot.phone}`}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="btn end shadow-none mt-3"
                                                >
                                                  End
                                                </a>
                                              ) : null}
                                            </ul>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                );
                              })}
                            </td>

                            <td className="table-margin">
                              {appointments[key].map((slot, index) => {
                                return (
                                  <>
                                    {slot.slot === 3 && (
                                      <>
                                        {slot.slotStatus === "FREE" ? (
                                          <>
                                            <button
                                              className="blockBtn"
                                              onClick={() => {
                                                blockSlot(
                                                  slot.slot,
                                                  key,
                                                  filter.days
                                                );
                                              }}
                                            >
                                              BLOCK
                                            </button>
                                            <br />
                                            <br />
                                            <p className="ps-4">FREE</p>
                                          </>
                                        ) : slot.slotStatus === "BLOCKED" ? (
                                          <>
                                            <div
                                              style={{
                                                width: "100%",
                                                height: "100%",
                                              }}
                                            >
                                              <button
                                                className="ReleaseBtn"
                                                onClick={() =>
                                                  unblockSlot(
                                                    slot.slot,
                                                    key,
                                                    filter.days
                                                  )
                                                }
                                              >
                                                Release
                                              </button>
                                              <br />
                                              <br />
                                              <p className="ps-4">BLOCKED</p>
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            <ul
                                              style={{ marginBottom: "30px" }}
                                            >
                                              <button
                                                className="ReleaseBtn mb-3"
                                                onClick={() =>
                                                  unblockSlot(
                                                    slot.slot,
                                                    key,
                                                    filter.days
                                                  )
                                                }
                                              >
                                                Release
                                              </button>
                                              <span className="ms-3">
                                                {slot.stylist_seen ? (
                                                  <BsFillPersonCheckFill />
                                                ) : null}
                                              </span>
                                              <span className="ms-3">
                                                <a
                                                  href={`https://api.whatsapp.com/send?phone=91${slot.phone.trim()}&text=Hi ${
                                                    slot.name
                                                  },This is Misha from GetLook - Salon at Home.
                                                  %0aIt would great if you can send your Whats App location as we are unable to get your exact Address. This will help our stylist to book Cab for your Location.
                                                  `}
                                                  target="_blank"
                                                  className="text-dark"
                                                >
                                                  <BsWhatsapp />
                                                </a>
                                              </span>
                                              {slot?.customer_discount < 10 && (
                                                <span className="ms-3">
                                                  <img
                                                    src="/Assets/rupees.svg"
                                                    alt="rupees"
                                                    width={15}
                                                  />
                                                </span>
                                              )}

                                              <li>Area : {slot.area.name}</li>
                                              <li>Timing: {slot.timing} </li>
                                              <li>
                                                Total Price: Rs.{" "}
                                                {totalCost(
                                                  slot.appointmentitem_set
                                                )}
                                              </li>

                                              <li>
                                                Service Time : &nbsp;
                                                {service(
                                                  slot.appointmentitem_set
                                                )}
                                              </li>

                                              <Accordion>
                                                <Accordion.Item eventKey="0">
                                                  <Accordion.Header>
                                                    Live Status
                                                  </Accordion.Header>
                                                  <Accordion.Body>
                                                    <ul className="liveStatus">
                                                      <li>
                                                        In Transit:&nbsp;
                                                        {slot.stylist_comment?.includes(
                                                          "TRANSIT at"
                                                        )
                                                          ? slot.stylist_comment
                                                              ?.split(
                                                                "TRANSIT at"
                                                              )[1]
                                                              ?.split(" ")[1]
                                                          : null}
                                                      </li>
                                                      <li>
                                                        Start:&nbsp;
                                                        {slot.stylist_comment?.includes(
                                                          "START at"
                                                        )
                                                          ? slot.stylist_comment
                                                              ?.split(
                                                                "START at"
                                                              )[1]
                                                              ?.split(" ")[1]
                                                          : null}
                                                      </li>
                                                      <li>
                                                        End:&nbsp;
                                                        {slot.stylist_comment?.includes(
                                                          "END at"
                                                        )
                                                          ? slot.stylist_comment
                                                              ?.split(
                                                                "END at"
                                                              )[1]
                                                              ?.split(" ")[1]
                                                          : null}
                                                      </li>
                                                      <li>
                                                        Time Left : &nbsp;
                                                        {slot.stylist_comment?.includes(
                                                          "END at"
                                                        ) ? null : slot.stylist_comment?.includes(
                                                            "START at"
                                                          ) ? (
                                                          <>
                                                            {timeLeft(
                                                              slot.stylist_comment,
                                                              slot.appointmentitem_set
                                                            )}
                                                          </>
                                                        ) : null}
                                                      </li>
                                                    </ul>
                                                  </Accordion.Body>
                                                </Accordion.Item>
                                              </Accordion>

                                              <li>
                                                Comment :{" "}
                                                {commentMenu(
                                                  slot?.stylist_comment
                                                )}
                                                {/* {slot.stylist_comment?.includes(
                                                  "Started Late"
                                                ) ? (
                                                  <>
                                                    Started Late
                                                    {slot.stylist_comment?.includes(
                                                      "Extra Time"
                                                    )
                                                      ? "| Extra Time"
                                                      : slot.stylist_comment?.includes(
                                                          "More Time"
                                                        )
                                                      ? "| More Time"
                                                      : slot.stylist_comment?.includes(
                                                          "Finished Fast"
                                                        )
                                                      ? "| Finished Fast"
                                                      : null}
                                                  </>
                                                ) : null} */}
                                              </li>

                                              <li>
                                                <div className="d-flex align-items-center">
                                                  <MdLocationOn />

                                                  <span className="ms-2">
                                                    {slot.feedback_form_received ? (
                                                      " Address Verified"
                                                    ) : (
                                                      <a
                                                        style={{
                                                          color: "#a61009",
                                                        }}
                                                        href={`${process.env.REACT_APP_URL}/mapLocationAdmin/?id=${slot.id}`}
                                                        target="_blank"
                                                      >
                                                        Address Not Verified
                                                      </a>
                                                    )}
                                                  </span>
                                                </div>
                                              </li>

                                              {slot.stylist_comment?.includes(
                                                "END at"
                                              ) ? null : slot.stylist_comment?.includes(
                                                  "START at"
                                                ) ? (
                                                <ProgressBar
                                                  servicetime={
                                                    slot.appointmentitem_set
                                                  }
                                                  startTiming={
                                                    slot.stylist_comment
                                                  }
                                                  percentage={0}
                                                  slotDays={filter.days}
                                                  slotId={slot.id}
                                                  phone={slot.phone}
                                                  // timeleft={timeLeft(slot.stylist_comment,slot.appointmentitem_set
                                                  //   )}
                                                  sTime={slot?.stylist_comment}
                                                  eTime={
                                                    slot?.appointmentitem_set
                                                  }
                                                />
                                              ) : null}

                                              {slot.status === 0 ? (
                                                <a
                                                  // href={`${process.env.REACT_APP_URL2}/admin/api/appointment/?date__exact=${filter.days}&id=${slot.id}`}
                                                  // target="_blank"
                                                  // rel="noopener noreferrer"
                                                  // className="btn  pending shadow-none mt-3"
                                                  href={`${process.env.REACT_APP_URL2}/admin/api/appointment/?phone=${slot.phone}`}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="btn pending shadow-none mt-3"
                                                >
                                                  Pending
                                                </a>
                                              ) : slot.status === 1 ? (
                                                <a
                                                  // href={`${process.env.REACT_APP_URL2}/admin/api/appointment/?date__exact=${filter.days}&id=${slot.id}`}
                                                  // target="_blank"
                                                  // rel="noopener noreferrer"
                                                  // className="btn  confirmed shadow-none mt-3"
                                                  href={`${process.env.REACT_APP_URL2}/admin/api/appointment/?phone=${slot.phone}`}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="btn  confirmed shadow-none mt-3"
                                                >
                                                  Confirmed
                                                </a>
                                              ) : slot.status === 13 ? (
                                                <a
                                                  // href={`${process.env.REACT_APP_URL2}/admin/api/appointment/?date__exact=${filter.days}&id=${slot.id}`}
                                                  // target="_blank"
                                                  // rel="noopener noreferrer"
                                                  // className="btn inTransit shadow-none mt-3"
                                                  href={`${process.env.REACT_APP_URL2}/admin/api/appointment/?phone=${slot.phone}`}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="btn  inTransit shadow-none mt-3"
                                                >
                                                  In Transit
                                                </a>
                                              ) : slot.status === 3 ? (
                                                <a
                                                  // href={`${process.env.REACT_APP_URL2}/admin/api/appointment/?date__exact=${filter.days}&id=${slot.id}`}
                                                  // target="_blank"
                                                  // rel="noopener noreferrer"
                                                  // className="btn stylist_confirm shadow-none mt-3"
                                                  href={`${process.env.REACT_APP_URL2}/admin/api/appointment/?phone=${slot.phone}`}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="btn  stylist_confirm shadow-none mt-3"
                                                >
                                                  Stylist Confirmed
                                                </a>
                                              ) : slot.status === 5 ? (
                                                <a
                                                  // href={`${process.env.REACT_APP_URL2}/admin/api/appointment/?date__exact=${filter.days}&id=${slot.id}`}
                                                  // target="_blank"
                                                  // rel="noopener noreferrer"
                                                  // className="btn end shadow-none mt-3"
                                                  href={`${process.env.REACT_APP_URL2}/admin/api/appointment/?phone=${slot.phone}`}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="btn end shadow-none mt-3"
                                                >
                                                  End
                                                </a>
                                              ) : null}
                                            </ul>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                );
                              })}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <h1>No Data Available</h1>
                )}
              </tbody>
            </table>
            <div className="d-flex justify-content-center pb-3">
              <Pagination filter={filter} setFilter={setFilter} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
