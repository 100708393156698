import React from "react";

const Pagination = ({ filter, setFilter }) => {
  // const paginate = [0,1, 2, 3, 4, 5,6,7,8,9,10]
  const paginate = [0, 1, 2, 3, 4, 5];

  const pageLimit = (elm) => {
    setFilter({
      ...filter,
      page: elm,
    });
  };

  return (
    <>
      <div className="d-flex">
        <div className="paginationBtn">
          <img src="Assets/lessthen.svg" alt="lessthen" width={8} />
        </div>
        {paginate.map((elm) => {
          return (
            <>
              <div
                className={`${
                  filter.page === elm
                    ? "pagination-active"
                    : "paginationBtn curser"
                }`}
                onClick={() => pageLimit(elm)}
              >
                <span>{elm}</span>
              </div>
            </>
          );
        })}

        <div className="paginationBtn">
          <img src="Assets/greaterthen.svg" alt="greaterthen" width={8} />
        </div>
      </div>
    </>
  );
};

export default Pagination;
