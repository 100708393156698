import React, { useEffect, useState } from "react";
import { cities } from "../../utils/city";
import Switch from "react-switch";
import { days } from "../../utils/day";
import Pagination from "../Pagination";

const Filter = ({
  filter,
  setFilter,
  appointments,
  setslotNum,
  slotNum,
  fetchData,
  setAppointments,
  setLoader,
}) => {
  const localcity = localStorage.getItem("city").toString();

  const [sToggle, setSToggle] = useState(filter.owner);

  const handleChang = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    console.log(e);
    setFilter({
      ...filter,
      [name]: value,
    });
    console.log(filter);
  };
  useEffect(() => {
    setFilter({
      ...filter,
      owner: sToggle,
    });
  }, [sToggle]);

  const toggleData = () => {
    const lab = JSON.parse(localStorage.getItem("fetchData"));
    if (lab) {
      setSToggle(lab.owner);
    }
  };
  useEffect(() => {
    toggleData();
  }, []);

  const setSlot = (e) => {
    let value = e.target.value;

    setslotNum(value);
  };

  const handleChange = () => {
    setSToggle(!sToggle);
  };

  const resetDefault = () => {
    localStorage.removeItem("fetchData");
    setslotNum("");
    setSToggle(false);
    window.location.reload();
  };

  return (
    <>
      <div className="filterPaggContainer">
        <div className="filterPaggDiv">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-9 d-flex align-items-center">
                <div className="filterIconContainer me-4">
                  <img src="Assets/filter.svg" alt="filter" />
                </div>
                <div className="filterContainer d-flex">
                  <div className="result d-flex align-items-center me-3">
                    {localcity !== "null" ? (
                      <>
                        {/* <select name="city" >
                      
                      
                          <>
                            <option
                              className="mb-0"
                              disabled
                              selected
                            >
                              {localcity}
                            </option>
                          </>
                        
                      
                    </select> */}
                        <input type="text" value={localcity} disabled />
                      </>
                    ) : (
                      <>
                        <select name="city" onChange={handleChang}>
                          <option disabled>Select-city</option>
                          {cities.map((city) => {
                            return (
                              <>
                                <option
                                  className="mb-0"
                                  value={city}
                                  selected={filter?.city === city}
                                >
                                  {city}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </>
                    )}
                  </div>
                  <div className="date d-flex align-items-center me-3">
                    <input
                      type="date"
                      name="days"
                      onChange={handleChang}
                      value={filter?.days}
                    />
                  </div>
                  <div className="result d-flex align-items-center">
                    <select name="pageLimit" onChange={handleChang}>
                      <option selected>Select-limit</option>
                      {/* <option value="10" selected={filter?.pageLimit === "10"}>
                        10
                      </option> */}
                      <option value="25" selected={filter?.pageLimit === "25"}>
                        25
                      </option>
                      <option value="50" selected={filter?.pageLimit === "50"}>
                        50
                      </option>
                      <option value="75" selected={filter?.pageLimit === "75"}>
                        75
                      </option>
                      <option
                        value="100"
                        selected={filter?.pageLimit === "100"}
                      >
                        100
                      </option>
                      <option
                        value="150"
                        selected={filter?.pageLimit === "150"}
                      >
                        150
                      </option>
                      <option
                        value="200"
                        selected={filter?.pageLimit === "200"}
                      >
                        200
                      </option>
                    </select>
                  </div>

                  <div className="date d-flex align-items-center ms-3">
                    <select name="slot" onChange={setSlot}>
                      <option selected disabled>
                        Free Slot
                      </option>
                      <option
                        value="all"
                        selected={slotNum && slotNum === "all"}
                      >
                        All Slot
                      </option>
                      <option value="1" selected={slotNum && slotNum === 1}>
                        Slot 1
                      </option>
                      <option value="2" selected={slotNum && slotNum === 2}>
                        Slot 2
                      </option>
                      <option value="3" selected={slotNum && slotNum === 3}>
                        Slot 3
                      </option>
                    </select>
                  </div>

                  {localcity === "null" ? (
                    <a
                      className="btn btn-primary text-white ms-4"
                      href={`${process.env.REACT_APP_URL}/locationview/?city=${filter.city}&stylist_id=0&date=${filter.days}`}
                      target="_blank"
                      style={{
                        fontSize: "12px",
                        borderRadius: "8px",
                        width: "80px",
                      }}
                    >
                      City Map
                    </a>
                  ) : (
                    <a
                      className="btn btn-primary text-white ms-4"
                      href={`${process.env.REACT_APP_URL}/locationview/?city=${localcity}&stylist_id=0&date=${filter.days}`}
                      target="_blank"
                      style={{
                        fontSize: "12px",
                        borderRadius: "8px",
                        width: "80px",
                      }}
                    >
                      City Map
                    </a>
                  )}

                  <div
                    className="result d-flex align-items-center ms-4 curser"
                    onClick={resetDefault}
                  >
                    {Object.keys(appointments).length} Results
                  </div>
                </div>
              </div>

              {/* <div className="col-lg-3 d-flex justify-content-end">
                {
                  <>
                    All &nbsp;
                    <Switch
                      onChange={handleChange}
                      checked={sToggle}
                      onColor="#86d3ff"
                      onHandleColor="#2693e6"
                      handleDiameter={30}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={20}
                      width={48}
                      className="react-switch"
                    />
                    &nbsp;Only me
                  </>
                }
              </div> */}
              <div className="col-lg-3 d-flex justify-content-end align-items-center">
                <div className="d-flex justify-content-center">
                  <Pagination filter={filter} setFilter={setFilter} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Filter;
