export const cities = [
  "Bangalore",
  "Aurangabad",
  "Chennai",
  "Mumbai",
  "Pune",
  "Jodhpur",
  "Kolkata",
  "Hyderabad",
  "Noida",
  "Gorakhpur",
  "Siliguri",
];
