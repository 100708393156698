import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { HiMenuAlt1 } from "react-icons/hi";
import OutsideClickHandler from "react-outside-click-handler";
import FilterMobile from "./Filter Mobile";
import { useLocation, useNavigate } from "react-router-dom";
import Switch from "react-switch";

const Navbar = ({
  filter,
  setFilter,
  appointments,
  setslotNum,
  slotNum,
  fetchData,
}) => {
  const [filterMobileToggle, setFilterMobileToggle] = useState(false);
  const navigate = useNavigate();
  const [width, setWidth] = useState(0);
  const [sToggle, setSToggle] = useState(filter.owner);
  const ww = () => {
    if (width <= 992) {
      setWidth(window.innerWidth);
    } else {
      setWidth(window.innerWidth);
    }
  };

  const handleChang = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    console.log(e);
    setFilter({
      ...filter,
      [name]: value,
    });
    console.log(filter);
  };
  const handleChange = () => {
    setSToggle(!sToggle);
  };
  useEffect(() => {
    setFilter({
      ...filter,
      owner: sToggle,
    });
  }, [sToggle]);

  useEffect(() => {
    window.addEventListener("resize", ww);
    ww();
    return () => {
      window.removeEventListener("resize", ww);
    };
  }, [window.innerWidth]);

  const logoutUser = () => {
    localStorage.removeItem("fetchData");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    sessionStorage.clear();
    navigate("/", { replace: false });
  };

  return (
    <>
      <div className="container-fluid d-flex justify-content-between navbarContainerNew px-md-5">
        <div
          className="toggleFilterContainer"
          onClick={() => setFilterMobileToggle(!filterMobileToggle)}
        >
          <HiMenuAlt1 />
        </div>

        <Link to="/" className="navbar-brand">
          <img
            src="Assets/getlook_logo.svg"
            alt="logo"
            className="img-fluid"
            width={140}
          />
        </Link>

        <div className="d-flex align-items-center">
          <div className="react_switch me-4">
            <span style={{ color: "#fff" }}>All &nbsp;</span>
            <Switch
              onChange={handleChange}
              checked={sToggle}
              onColor="#86d3ff"
              onHandleColor="#2693e6"
              handleDiameter={30}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={20}
              width={48}
              className="react-switch"
            />
            <span style={{ color: "#fff" }}>&nbsp;Only me</span>
          </div>

          <div className="menuDivNew d-flex align-items-center">
            <div className="welcomeContainer d-flex align-content-center">
              <img
                src="Assets/user.svg"
                alt="user"
                className="me-lg-2 img-fluid"
              />
              <div className="adminNameContainer">
                <div className="d-flex">
                  <p className="mb-0 font12x400 text-white">
                    Welcome{" "}
                    <span className="font12x600 text-white">
                      {localStorage.getItem("user")}
                    </span>
                  </p>
                  <img
                    src="Assets/dropdownarrow.svg"
                    className="img-fluid ms-2"
                    alt="arrow"
                    width={10}
                  />
                </div>
              </div>
              <div className="hoverMenu">
                <ul>
                  {width < 992 && (
                    <p className="mb-2 font12x400">
                      Welcome{" "}
                      <span className="font12x600">
                        {localStorage.getItem("user")}
                      </span>
                    </p>
                  )}
                  <li>
                    <a href="">Visit Site</a>
                  </li>
                  <li>
                    <a href="" onClick={logoutUser}>
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <OutsideClickHandler onOutsideClick={() => setFilterMobileToggle(false)}>
        {filterMobileToggle && (
          <div className="filterContainerMobile">
            <FilterMobile
              toggle={setFilterMobileToggle}
              filter={filter}
              setFilter={setFilter}
              appointments={appointments}
              setslotNum={setslotNum}
              slotNum={slotNum}
              fetchData={fetchData}
            />
          </div>
        )}
      </OutsideClickHandler>
    </>
  );
};

export default Navbar;
